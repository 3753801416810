<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">添加角色</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:creator_name="{row}">
        <span v-if="row.is_sys == 1">系统生成</span>
        <span v-else>{{row.creator_name}}</span>
      </template>
      <template v-slot:action="{row}">
        <!-- 超级管理员默认有所有权限不可修改、删除 -->
        <template v-if="row.is_sys !== 1">
          <el-button type="text" @click="showEdit(row)">权限</el-button>
          <el-button type="text" @click="remove(row)" v-if="is_sys == 1">删除</el-button>
        </template>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Edit,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      login_account: getStorage('account'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "角色", hidden: false },
        { name: "desc", label: "角色说明", hidden: false},
        { name: "count", label: "账号数量", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "creator_name", label: "创建人", hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/role/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/role/delete"
      );
    },
  }
}
</script>
