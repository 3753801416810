<template>
  <el-dialog
    top="3vh"
    append-to-body
    width="1100px"
    :title="!isChange ? '添加角色' : '权限设置'"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="角色名称" prop="name">
              <el-input
                v-model.trim="form.data.name"
                placeholder="请输入角色名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色说明" prop="desc">
              <el-input
                v-model="form.data.desc"
                placeholder="请输入角色说明"
                clearable
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tree
          :data="treeData"
          class="tree filter-tree tree-level2-bold"
          ref="ruleTree"
          show-checkbox
          node-key="id"
          :default-checked-keys="checkedKeys"
          :props="defaultProps"
          :default-expand-all="isAllExpanded">
        </el-tree>
      
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import {setStorage} from '@/storage/index.js'
  export default {
    name: 'RoleEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            desc: '',
            access: '',
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        defaultProps: {
          children: 'child',
          label: 'name'
        },
        treeData: [],
        checkedKeys: [], // 反显节点的 key 的数组
        isAllExpanded: true, // 默认展开所有节点
      }
    },
    methods: {
      getDetail(row) {
        this.isChange = !!row.id ? true : false;
        this.$http.get('/admin/role/detail',{params:{id:row.id}}).then(res => {
          if(res.code == 1) {
            let arrData = res.data.access_list.map((item,index) => {
              return {
                ...item,
                id:'1000'+index,
                level: 1,
              }
            });
            this.treeData = [{
              is_selected: 0,
              name:'全选',
              id: 0,
              level: 0,
              child: arrData
            }]
            console.log(this.treeData, "treeData")
            const idsWithStatusOne = this.extractIdsWithStatusOne(this.treeData);
            this.checkedKeys = idsWithStatusOne; // 反显的id集合
            if(!!row.id) {
              this.form.data.name = res.data.name;
              this.form.data.desc = res.data.desc;
              this.form.data.id = res.data.id;
            }
          }
        })
      },
      // 将tree列表中把is_selected为1的对应id取出来放一个数组中
      extractIdsWithStatusOne(tree) {
        const ids = []; // 用于存储符合条件的id
        // 递归遍历函数
        function traverse(nodes) {
          nodes.forEach(node => {
            if (node.is_selected === 1) {
              ids.push(node.id); // 如果status为1，则添加id到数组中
            }
            if (node.child && node.child.length > 0) {
              traverse(node.child); // 递归遍历子节点
            }
          });
        }
        traverse(tree); // 从根节点开始遍历
        return ids; // 返回符合条件的id数组
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
             //tree选中节点的二级节点id集合
            let _ids = this.$refs.ruleTree.getCheckedNodes().filter(v => v.level !== 0 && v.level !== 1).map(v => v.id).join(","); 
            console.log(_ids,"rules")
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              desc: this.form.data.desc,
              access: _ids
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/role/edit' : '/admin/role/add'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！');
                if(this.isChange == true) {
                  this.getAccess(); // 获取权限菜单列表
                  setTimeout(() => {
                    location.reload();
                  }, 200)
                }
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      getAccess() {
        this.$http.get('/admin/admin/getAccess',{params:{}}).then(res => {
          if(res.code == 1) {
            setStorage('access_list', res.data.access_list); // 该账号的权限菜单列表
            setStorage('is_sys', res.data.is_sys); //是否是系统创建 1:是（超级管理员） 0:否
            setStorage('city', res.data.city)
          }
        })
      },
    }
  }
</script>
<style scoped>
::v-deep .el-tree-node__children .el-tree-node__children {
  display:flex !important;
}
</style>
